<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=7 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">{{formtitle}}<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb ">
                  <div class="form-step">
                    <el-steps :active="active" align-center process-status="process">
                      <el-step title="步骤 1"></el-step>
                      <el-step title="步骤 2"></el-step>
                      <el-step title="步骤 3"></el-step>
                    </el-steps>
                  </div>
                  <el-form :model="model" :label-position="labelPosition" :rules="rules" label-width="100px"
                    class="elform" ref="elForm">
                    <template v-if="active==0">
                      <div class="bodywrap">
                        <el-form-item class="formL realname" label="姓名" prop="realname">
                          <el-input v-model="model.realname"></el-input>
                        </el-form-item>
                      </div>
                      <div class="bodywrap">
                        <el-form-item class="formL" label="性别">
                          <el-radio-group v-model="sexname" size="small">
                            <el-radio label="nan" border>男</el-radio>
                            <el-radio label="nv" border>女</el-radio>
                          </el-radio-group>
                        </el-form-item>
                        <el-form-item class="" label="生日" prop="Birthday">
                          <el-col :span="11">
                            <el-date-picker v-model="model.Birthday" type="date" placeholder="选择生日">
                            </el-date-picker>
                          </el-col>
                        </el-form-item>
                      </div>
                      <div class="bodywrap">
                        <el-form-item class="formL" label="体重" prop="weight">
                          <el-input placeholder="请输入内容" v-model="model.weight">
                            <template slot="append">KG</template>
                          </el-input>
                        </el-form-item>
                        <el-form-item class="formL" label="身高" prop="height">
                          <el-input placeholder="请输入内容" v-model="model.height">
                            <template slot="append">CM</template>
                          </el-input>
                        </el-form-item>

                      </div>
                      <div class="bodywrap" v-show="fasle">
                        <el-form-item class="formL shape" label="体型">
                          <el-checkbox-group v-model="shapeData">
                            <el-checkbox label="挺胸型"></el-checkbox>
                            <el-checkbox label="驼背型"></el-checkbox>
                            <el-checkbox label="平肩型"></el-checkbox>
                            <el-checkbox label="腹微突"></el-checkbox>
                            <el-checkbox label="腹中突"></el-checkbox>
                            <el-checkbox label="腹重突"></el-checkbox>
                          </el-checkbox-group>
                        </el-form-item>
                      </div>
                      <el-form-item label="体型图片" class="upload">
                        <div class="uploadwrap">
                          <div class="fieldimg">
                            <el-tag type="warning">正面照</el-tag>
                            <el-upload style="float:left;width:180px;" class="avatar-uploader" :show-file-list="false"
                              :headers="myHeaders" list-type="picture-card" :on-preview="handlePictureCardPreview"
                              :on-remove="handleRemove" :action="uploadUrl" name="file"
                              accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="fileList"
                              :on-success="busSuccess" :on-error="busError" :on-change="busChange">
                              <img v-if="model.frontshapepicpath" :src="model.frontshapepicpathThum" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                          </div>
                          <div class="fieldimg">
                            <el-tag type="warning">背面照</el-tag>
                            <el-upload style="float:left;width:180px;" class="avatar-uploader" :show-file-list="false"
                              :headers="myHeaders" list-type="picture-card" :on-preview="handlePictureCardPreview2"
                              :on-remove="handleRemove2" :action="uploadUrl" name="file"
                              accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="fileList2"
                              :on-success="busSuccess2" :on-error="busError2" :on-change="busChange2">
                              <img v-if="model.backshapepicpath" :src="model.backshapepicpathThum" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>

                          </div>
                          <div class="fieldimg">
                            <el-tag type="warning">侧面照</el-tag>
                            <el-upload style="float:left;width:180px;" class="avatar-uploader" :show-file-list="false"
                              :headers="myHeaders" list-type="picture-card" :on-preview="handlePictureCardPreview3"
                              :on-remove="handleRemove3" :action="uploadUrl" name="file"
                              accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="fileList3"
                              :on-success="busSuccess3" :on-error="busError3" :on-change="busChange3">
                              <img v-if="model.sideshapepicpath" :src="model.sideshapepicpathThum" class="avatar">
                              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item>
                        <el-button @click="onsubmitForm('elForm','next')" class="next">下一步</el-button>
                      </el-form-item>
                    </template>
                    <template v-if="active==1">
                      <div class="bodywrap">
                        <el-form-item label="A领围" prop="neckgirth" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入A领围"
                            v-model="model.neckgirth">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">由脖子绕一周</el-tag>
                              <el-image style="" :src="require(`@/assets/data/a-lingwei-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/a-lingwei-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item label="E胸围" prop="bustgirth" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入E胸围"
                            v-model="model.bustgirth">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">平夹底绕胸围一周</el-tag>
                              <el-image style="" :src="require(`@/assets/data/e-xiongwei-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/e-xiongwei-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                      </div>

                      <div class="bodywrap">
                        <el-form-item label="D袖长" prop="sleevelength" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入D袖长"
                            v-model="model.sleevelength">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">由袖顶到虎口处</el-tag>
                              <el-image style="" :src="require(`@/assets/data/d-xiuchang-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/d-xiuchang-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item label="W袖围" prop="wristgirth" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入W袖围"
                            v-model="model.wristgirth">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">由大臂最粗处绕大臂一周</el-tag>
                              <el-image style="" :src="require(`@/assets/data/w-xiuwei-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/w-xiuwei-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <el-form-item>
                        <el-button type="primary" plain @click="onsubmitForm('elForm','prev')">上一步</el-button>
                        <el-button @click="onsubmitForm('elForm','next')" class="next">下一步</el-button>
                      </el-form-item>
                    </template>
                    <template v-if="active==2">
                      <div class="bodywrap">
                        <el-form-item label="G裤腰围" prop="waistline" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入G裤腰围"
                            v-model="model.waistline">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">在肚脐下2CM处绕腰围一周</el-tag>
                              <el-image style="" :src="require(`@/assets/data/g-kuyaowei-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/g-kuyaowei-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item label="X裤长" prop="trouserslength" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入X裤长"
                            v-model="model.trouserslength">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">侧面裤腰顶到平脚底</el-tag>
                              <el-image style="" :src="require(`@/assets/data/x-kuchang-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/x-kuchang-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <div class="bodywrap">
                        <el-form-item label="J大腿围" prop="thighgirth" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入J大腿围"
                            v-model="model.thighgirth">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">在裆底处绕大腿一周</el-tag>
                              <el-image style="" :src="require(`@/assets/data/j-datuiwei-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/j-datuiwei-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item label="L小腿围" prop="crusgirth" class="upload bodysize">
                          <el-input oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入L小腿围"
                            v-model="model.crusgirth">
                            <template slot="append">CM</template>
                          </el-input>
                          <div class="uploadwrap">
                            <div class="fieldimg">
                              <el-tag type="warning">在绕小腿一周</el-tag>
                              <el-image style="" :src="require(`@/assets/data/l-xiaotuiwei-${sexname}.jpg`)"
                                :preview-src-list="[require(`@/assets/data/l-xiaotuiwei-${sexname}.jpg`)]">
                              </el-image>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <el-form-item>
                        <el-button type="primary" plain @click="onsubmitForm('elForm','prev')">上一步</el-button>
                        <el-button @click="onsubmitForm('elForm','next')" class="next">提交</el-button>
                      </el-form-item>
                    </template>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  usermeasuredatagetdetail,
  usermeasuredatainsertorupdate,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      active: 0,
      formtitle: '新增数据采集',
      id: 0,
      dialogImageUrl: '',
      labelPosition: 'right',
      dialogimgVisible: false,
      fileList: [],
      fileList2: [],
      fileList3: [],
      shapeData: [],
      sexname: 'nan',
      show: false,
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      isauthresult: 1,
      model: {
        realname: '',
        Birthday: '',
        sex: '男',
        height: '',
        weight: '',
        shape: '',
        frontshapepicpath: '',
        backshapepicpath: '',
        sideshapepicpath: '',
        neckgirth: '',
        bustgirth: '',
        waistline: '',
        sleevelength: '',
        wristgirth: '',
        trouserslength: '',
        thighgirth: '',
        crusgirth: '',
        Id: 0,
      },
      rules: {
        realname: [{ required: true, message: '请输入姓名' }],
        Birthday: [{ required: true, message: '请输入生日', trigger: 'blur'  }],
        height: [{ required: true, message: '请输入身高' }],
        weight: [{ required: true, message: '请输入体重' }],
        //shape: [{ required: true, message: '请选择体型' }],
        frontshapepicpath: [{ required: true, message: '请上传正面照' }],
        backshapepicpath: [{ required: true, message: '请上传背面照' }],
        sideshapepicpath: [{ required: true, message: '请上传侧面照' }],
        neckgirth: [{ required: true, message: '请输入A领围' }],
        bustgirth: [{ required: true, message: '请输入E胸围' }],
        waistline: [{ required: true, message: '请输入G裤腰围' }],
        sleevelength: [{ required: true, message: '请输入D袖长' }],
        wristgirth: [{ required: true, message: '请输入W袖围' }],
        trouserslength: [{ required: true, message: '请输入X裤长' }],
        thighgirth: [{ required: true, message: '请输入J大腿围' }],
        crusgirth: [{ required: true, message: '请输入L小腿围' }],
      },
    };
  },
  methods: {
    oncerttypechange(item) {
      this.form.certtypeid = item.id;
      this.form.certtype = item.data_title;
    },
    onsubmitForm(formName, step) {
      if (step == 'prev') {
        if (this.active == 1) {
          this.active = 0;
        }
        if (this.active == 2) {
          this.active = 1;
        }
        return;
      }
      if (step == 'next') {
        if (this.active == 0) {
          if (this.model.realname == '') {
            this.$message({
              showClose: true,
              message: '请输入姓名',
              type: 'warning',
            });
            return;
          }
          if (this.model.Birthday == '') {
            this.$message({
              showClose: true,
              message: '请输入生日',
              type: 'warning',
            });
            return;
          }
          if (this.model.weight == '') {
            this.$message({
              showClose: true,
              message: '请输入体重',
              type: 'warning',
            });
            return;
          }
          if (this.model.height == '') {
            this.$message({
              showClose: true,
              message: '请输入身高',
              type: 'warning',
            });
            return;
          }

          if (this.model.frontshapepicpath == '') {
            this.$message({
              showClose: true,
              message: '请上传正面照',
              type: 'warning',
            });
            return;
          }
          if (this.model.backshapepicpath == '') {
            this.$message({
              showClose: true,
              message: '请上传背面照',
              type: 'warning',
            });
            return;
          }
          if (this.model.sideshapepicpath == '') {
            this.$message({
              showClose: true,
              message: '请上传侧面照',
              type: 'warning',
            });
            return;
          }
          this.active = 1;
        } else if (this.active == 1) {
          if (this.model.neckgirth == '') {
            this.$message({
              showClose: true,
              message: '请输入A领围',
              type: 'warning',
            });
            return;
          }
          if (this.model.bustgirth == '') {
            this.$message({
              showClose: true,
              message: '请输入E胸围',
              type: 'warning',
            });
            return;
          }
          if (this.model.sleevelength == '') {
            this.$message({
              showClose: true,
              message: '请输入D袖长',
              type: 'warning',
            });
            return;
          }
          if (this.model.wristgirth == '') {
            this.$message({
              showClose: true,
              message: '请输入W袖围',
              type: 'warning',
            });
            return;
          }
          this.active = 2;
        } else if (this.active == 2) {
          if (this.model.waistline == '') {
            this.$message({
              showClose: true,
              message: '请输入G裤腰围',
              type: 'warning',
            });
            return;
          }
          if (this.model.trouserslength == '') {
            this.$message({
              showClose: true,
              message: '请输入X裤长',
              type: 'warning',
            });
            return;
          }
          if (this.model.thighgirth == '') {
            this.$message({
              showClose: true,
              message: '请输入J大腿围',
              type: 'warning',
            });
            return;
          }
          if (this.model.crusgirth == '') {
            this.$message({
              showClose: true,
              message: '请输入L小腿围',
              type: 'warning',
            });
            return;
          }
          usermeasuredatainsertorupdate(this.model).then((res) => {
            if (res.data.code === 1) {
              this.$message({
                message: '恭喜你，提交成功',
                type: 'success',
              });
              setTimeout(function () {
                window.location.href = '/user/data/index';
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      }
    },
    onusermeasuredatagetdetail() {
      var that = this;
      usermeasuredatagetdetail({ id: this.id }).then((res) => {
        if (res.data.code === 1) {
          this.model.realname = res.data.result.realname;
          this.model.Birthday = res.data.result.birthday;
          this.model.sex = res.data.result.sex;
          this.model.height = res.data.result.height;
          this.model.weight = res.data.result.weight;
          this.model.shape = res.data.result.shape;
          this.model.frontshapepicpath = res.data.result.frontshapepicpath;
          this.model.frontshapepicpathThum = res.data.result.frontshapepicpathThum;
          this.model.backshapepicpath = res.data.result.backshapepicpath;
          this.model.backshapepicpathThum = res.data.result.backshapepicpathThum;
          this.model.sideshapepicpath = res.data.result.sideshapepicpath;
          this.model.sideshapepicpathThum = res.data.result.sideshapepicpathThum;
          this.model.neckgirth = res.data.result.neckgirth;
          this.model.bustgirth = res.data.result.bustgirth;
          this.model.waistline = res.data.result.waistline;
          this.model.sleevelength = res.data.result.sleevelength;
          this.model.wristgirth = res.data.result.wristgirth;
          this.model.trouserslength = res.data.result.trouserslength;
          this.model.thighgirth = res.data.result.thighgirth;
          this.model.crusgirth = res.data.result.crusgirth;
          this.model.Id = res.data.result.id;
          this.sexname = this.model.sex == '男' ? 'nan' : 'nv';
          //this.shapeData = this.model.shape.split(',');
          this.fileList.push({
            url: this.model.frontshapepicpathThum,
          });
          this.fileList2.push({
            url: this.model.backshapepicpathThum,
          });
          this.fileList3.push({
            url: this.model.sideshapepicpathThum,
          });
          var mydate = new Date(this.model.birthday);
          var value = `${mydate.getFullYear()}/${
            mydate.getMonth() + 1
          }/${mydate.getDate()}`;
          //this.model.Birthday = value;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleRemove2(file, fileList) {
      console.log(file, fileList);
    },
    handleRemove3(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    handlePictureCardPreview3(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.fileList = fileList;
      if (response.code == 1) {
        this.model.frontshapepicpath = response.result.fileToken;
        this.model.frontshapepicpathThum = response.result.url + '/image/450x';
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    busSuccess2(response, file, fileList) {
      var that = this;
      this.fileList2 = fileList;
      if (response.code == 1) {
        this.model.backshapepicpath = response.result.fileToken;
        //console.log(this.model.backshapepicpath);
        this.model.backshapepicpathThum = response.result.url + '/image/450x';
      }
    },
    busError2(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange2(file, fileList) {
      if (fileList.length > 0) {
        this.fileList2 = [fileList[fileList.length - 1]];
      }
    },
    busSuccess3(response, file, fileList) {
      var that = this;
      this.fileList3 = fileList;
      if (response.code == 1) {
        this.model.sideshapepicpath = response.result.fileToken;
        this.model.sideshapepicpathThum = response.result.url + '/image/450x';
      }
    },
    busError3(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange3(file, fileList) {
      if (fileList.length > 0) {
        this.fileList3 = [fileList[fileList.length - 1]];
      }
    },
    locatefal() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addlocation() {
      this.dialogVisible = true;
    },
  },
  watch: {
    sexname(val) {
      this.model.sex = val == 'nan' ? '男' : '女';
    },
    shapeData(val) {
      this.model.shape = val.join(',');
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id > 0) {
      this.formtitle = '修改数据采集';
      this.onusermeasuredatagetdetail();
    }
  },
  mounted() {
    screenLeft;
    const self = this;
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.showbusinessend {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}

.accountset .fieldimg {
  width: 100%;
  text-align: center;
  margin: 5px;
}
.accountset .fieldimg >>> .van-tag {
  width: 100%;
  display: block;
  color: #ff976a;
  background-color: transparent;
  padding: 3px 0;
}
.accountset .upload .uploadwrap {
  display: flex;
}
.accountset .bodysize .fieldimg {
  width: 180px;
}
.accountset >>> .el-input-group {
  width: 200px;
}
.accountset >>> .el-image {
  width: 180px;
  height: 180px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px !important;
}
.accountset >>> .el-form-item__content,
.accountset >>> .el-date-editor {
  width: 200px;
}
.accountset .realname >>> .el-form-item__content {
  width: 500px;
}
.accountset .bodywrap {
  display: flex;
}
.accountset .shape >>> .el-form-item__content {
  width: 600px;
}
.accountset .avatar {
  width: 150px;
  height: 150px;
}
.accountset .content-rb .form-step {
  margin: 0 auto;
  margin-bottom: 30px;
}
.accountset .content-rb .form-step >>> .el-step__head.is-process,
.accountset .content-rb .form-step >>> .el-step__title.is-process {
  color: #67c23a;
  border-color: #67c23a;
}
</style>
